.footer{
    font-size: 15px;
    position: relative;
    z-index: 2;
    will-change: transform;
    background-color: rgb(39, 39, 43);
}

.footer_panel {
    border-color: rgba(255, 255, 255, 0.1);
    border-style: solid;
    border-width: 1px 0;
    background: rgba(0, 0, 0, 0.35);
    height: 103px;
}

.footer_holder {
    padding-left: 15px;
    margin-top: 20px;
    position: relative;
    margin-left: 145px;
}

.footer_copyright {
    float: left;
    line-height: 18px;
    color: #999;
}

.footer-menu {
    float: left;
    line-height: 18px;
}

.footer-menu_item {
    padding: 0 12px 0 0;
    float: left;
    position: relative;
    list-style: none;
}

.footer-menu_link {
    list-style: none;
    color: #999;
}

.container .footer_holder {
    margin-left: 145px;
}

.footer-menu_link:hover {
    color: #999;
}
